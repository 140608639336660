<script setup>
import { useNuxtApp, ref } from '#imports';
import { usePolicePopup } from '@/composables/usePolicePopup';
import { police } from '@/configs/police';
import { useGlobalStore } from '@/stores/global';
import { useRouter } from 'nuxt/app';

import UiButton from '@/components/ui/button/UiButton.vue';

const { openPolicePopup } = usePolicePopup();
const router = useRouter();
const { $tp, $gsap } = useNuxtApp();
const globalStore = useGlobalStore();

const contentRef = ref(null);
function startPreloader(href, label) {
  hiddenContent(href);

  sendAnalytic(label);
}
function hiddenContent(href) {
  $gsap.to(contentRef.value, {
    opacity: 0,
    duration: 1,
    onComplete: () => {
      globalStore.changeShowPreloader(true);

      if (href) {
        setTimeout(() => {
          router.push(href);
        }, 500);
      }
    },
  });
}

//<editor-fold desc="ANALYTIC">
import { commonAnalytic } from '@/analytic/common';

function sendAnalytic(label) {
  commonAnalytic.clickMenu(label);
}
//</editor-fold>
</script>
<template>
  <div class="login-finish-section">
    <div ref="contentRef" class="login-finish-section__content">
      <div class="login-finish-section__heading">
        <h2
          class="login-finish-section--heading login-finish-section__font--heading"
        >
          {{ $tp('Добро пожаловать на winston.ru') }}
        </h2>
      </div>

      <div class="login-finish-section__description">
        <p
          class="login-finish-section__font login-finish-section__font--description"
        >
          {{
            $tp(
              'Здесь вы сможете узнать о продукции и последних новинках бренда',
            )
          }}
        </p>
      </div>

      <div class="login-finish-section__actions">
        <UiButton
          theme="second-white"
          class="login-finish-section__button"
          text="Главная страница"
          @click="startPreloader('/', 'Главная страница')"
        />

        <UiButton
          theme="second-white"
          class="login-finish-section__button"
          text="Продукция"
          @click="startPreloader('/products/', 'Продукция')"
        />

        <UiButton
          theme="second-white"
          class="login-finish-section__button"
          text="Личный кабинет"
          @click="startPreloader('/profile', 'Личный кабинет')"
        />
      </div>

      <div class="login-finish-section__rules">
        <button
          type="button"
          class="login-finish-section__button login-finish-section__button--rules"
          @click="openPolicePopup(police.privacyPolicy)"
        >
          <span
            class="login-finish-section__font login-finish-section__font--rules"
          >
            Пользовательское Соглашение
          </span>
        </button>

        <span
          class="login-finish-section__font login-finish-section__font--rules login-finish-section__font--rules--light hidden-mobile"
          >|</span
        >

        <button
          type="button"
          class="login-finish-section__button login-finish-section__button--rules"
          @click="openPolicePopup(police.termsConditions)"
        >
          <span
            class="login-finish-section__font login-finish-section__font--rules"
          >
            Политика о конфиденциальности
          </span>
        </button>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.login-finish-section {
  display: flex;

  &__font {
    &--heading {
      @include h2;

      color: $color-white;

      @include media-breakpoint-down(lg) {
        font-size: em(36);
      }

      @include media-breakpoint-down(sm) {
        @include h2-mob;
      }
    }

    &--description {
      @include subtitle-m;

      color: $color-white;

      @include media-breakpoint-down(lg) {
        font-size: em(10);
      }

      @include media-breakpoint-down(sm) {
        @include subtitle-m-mob;
      }
    }

    &--rules {
      @include subtitle-s;

      @include media-breakpoint-down(lg) {
        font-size: em(7);
      }

      @include media-breakpoint-down(sm) {
        font-size: em(10);
      }

      &--light {
        color: rgba($color-white, 0.5);
      }
    }
  }

  &__content {
    width: 100%;
    margin: auto;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    padding-top: em(54);

    @include media-breakpoint-down(sm) {
      padding-top: em(70);
      padding-bottom: em(100);
    }
  }

  &__heading {
    max-width: em(758);
    padding: 0 em(26);
    margin-bottom: em(30);
    text-align: center;

    @include media-breakpoint-down(lg) {
      max-width: em(460);
      margin-bottom: em(23);
    }

    @include media-breakpoint-down(sm) {
      max-width: none;
    }
  }

  &__description {
    max-width: em(496);
    text-align: center;

    @include media-breakpoint-down(lg) {
      max-width: em(306);
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
      max-width: none;
      padding: 0 em(30);
    }
  }

  &__button {
    min-width: em(224);

    @include media-breakpoint-down(lg) {
      min-width: em(150);
    }

    &--rules {
      min-width: auto;
      cursor: pointer;
      color: rgba($color-white, 0.5);
      transition: $time-default ease-out;
      transition-property: color;

      @include hover {
        color: $color-white;
      }

      @include media-breakpoint-down(lg) {
        min-width: auto;
      }
    }
  }

  &__actions {
    display: flex;
    gap: em(16);
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: em(56);

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      margin-top: em(48);
      gap: em(12);
      margin-bottom: em(48);
    }
  }

  &__rules {
    margin-top: auto;
    position: absolute;
    bottom: em(40);
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: em(24);
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: em(26);

    &::before {
      position: absolute;
      top: 0;
      right: em(30);
      left: em(30);
      display: none;
      height: 0.5px;
      content: '';
      background-color: rgba($color-white, 0.5);

      @include media-breakpoint-down(sm) {
        display: block;
      }
    }

    @include media-breakpoint-down(sm) {
      position: relative;
      flex-direction: column;
      gap: em(20);
      left: auto;
      bottom: auto;
      transform: translateX(0);
    }
  }
}
</style>
